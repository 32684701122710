import React, {useCallback, useEffect, useState} from 'react';
import Input from "./components/form/Input";
import Form from "./components/form/Form";
import {useAxios} from "./components/AxiosProvider";
import Check from "./components/form/Check";

const Login = ({onSignInSuccess}) => {

  const [error, setError] = useState(null);
  const axios = useAxios();

  const [loading, setLoading] = useState(false);

  const [socialWindow, setSocialWindow] = useState(null);

  const signIn = useCallback((form) => {
    if (loading){
      return;
    }
    setLoading(true);
    const token = 'Basic ' + btoa(form.loginname + ':' + form.password);
    axios.get(`/users/me?remember-me=${form.remember}`, {headers: {'Authorization': token}})
      .then(res => {
        console.log('signin res', res);
        if(res.status === 200){
          onSignInSuccess(res.data);
        }else{
          setError(res.data && res.data.text ? res.data.text : 'sign in failed. unknown error');
        }
      })
      .catch(error => {
        if (!error.response){
          setError('Cannot connect to server.');
          return;
        }

        const type = error.response.headers['content-type'];
        const typeIsJson = type === 'application/json' || type === 'text/json';
        if (error.response.status >= 500 && !typeIsJson){
          setError('Server Side Error occurred. Try again later.');
          return;
        }

        setError(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [axios, loading, setLoading, setError, onSignInSuccess]);

  const signInWith = useCallback((url) => {
    const height = 500;
    const width = 600;
    const left = (window.screen.availLeft + (window.screen.availWidth / 2)) - (width / 2);
    const top = (window.screen.availTop + (window.screen.availHeight / 2)) - (height / 2);

    const config = `width=${width}, height=${height}, left=${left}, top=${top}, menubar=no, toolbar=no, status=no, scrollbars=no, resizable=no`;
    console.log('config', config);
    const loginWindow = window.open(url, 'Social Login', config);
    setSocialWindow(loginWindow);
  }, [setSocialWindow]);

  const signInWithFB = useCallback(() => {
    signInWith('/api/main/oauth2/authorization/facebook');
  }, [signInWith]);

  const signInWithGoogle = useCallback(() => {
    signInWith('/api/main/oauth2/authorization/google');
  }, [signInWith]);

  const signInWithApple = useCallback(() => {
    signInWith('/api/main/oauth2/authorization/apple');
  }, [signInWith]);

  useEffect(() => {
    const messageListener = (e) => {
      console.log('message', e.data, e);
      if(e.data === 'success'){
        console.log('message success');
        if (socialWindow)
          socialWindow.close();
        onSignInSuccess();
      }else{
        console.log('message failed');
      }
    };
    window.addEventListener("message", messageListener);
    return () => {
      window.removeEventListener("message", messageListener);
    }
  }, [socialWindow, onSignInSuccess])

  return (
    <>
      <div className="modal fade-in" tabIndex="-1" role="dialog" style={{display:'block'}}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <Form name={'login'} initialState={{loginname: '', password: '', remember: false}} onSubmit={signIn} className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Нэвтрэх</h5>
            </div>
            <div className="modal-body">
              {error &&
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
              }
              <Input label={'Нэвтрэх нэр'} name={'loginname'} required={true} autoFocus errorMessage={'Нэвтрэх нэрээ бичинэ үү!'}/>
              <Input label={'Нууц үг'} name={'password'} type={'password'} required={true} errorMessage={'Нууц үгээ бичинэ үү!'}/>
              <Check label={null} name={'remember'} valueLabel={'Намайг сана'}/>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                {loading && <i className="spinner-border spinner-border-sm" role={'status'}/>}
                <span> Нэвтрэх</span>
              </button>
              <button type={'button'} className={'btn btn-secondary'} onClick={signInWithFB} title={'With Facebook'}>
                <i className={'fab fa-facebook'}/>
              </button>
              <button type={'button'} className={'btn btn-secondary'} onClick={signInWithGoogle} title={'With Google'}>
                <i className={'fab fa-google'}/>
              </button>
              <button type={'button'} className={'btn btn-secondary'} onClick={signInWithApple} title={'With Apple'}>
                <i className={'fab fa-apple'}/>
              </button>
            </div>
          </Form>
        </div>
      </div>
      <div className="modal-backdrop fade-in show" id="backdrop" style={{display: 'block'}}/>
      <div style={{height: "100vh"}}>
        {/*<Map defaultCenter={[47.91, 106.92]} defaultZoom={12} provider={MapTilesProvider}/>*/}
      </div>

    </>
  );
};

export default Login;

import {Link} from "react-router-dom";
import StateChooser from "../components/StateChooser";
import useListPageHelper from "../utils/UseListPageHelper";
import {RelativeTime} from "../utils/DateTimeFormats";
import Pagination from "../components/Pagination";
import React, {useCallback, useEffect, useState} from "react";
import {useAxios} from "../components/AxiosProvider";
import {useToasts} from "../components/ToastProvider";
import ModalForm from "../components/ModalForm";
import Select from "../components/form/Select";
import File from "../components/form/File";
import Modal from "../components/Modal";
import Form from "../components/form/Form";
import FileUploader from "../components/FileUploader";

const ShowImages = ({id, closeModal}) => {

  const [images, setImages] = useState([]);
  const {showToast} = useToasts();
  const axios = useAxios();

  const load = useCallback((id) => {
    axios.get(`/herbs/${id}/images`).then(res => {
      setImages(res.data);
    })
  }, [axios]);

  const del = useCallback((id, image) => {
    axios.delete(`/herbs/${id}/images/${image}`).then(res => {
      showToast({title: 'Image Deleted'});
      load(id);
    })
  }, [axios, load, showToast]);

  useEffect(() => {
    load(id);
  }, [id, load]);

  return (
    <Modal title={`Түүхий эд #${id}-ийн зурагнууд`} show={true} close={closeModal} size={'lg'} >
      <table className={'table'}>
        <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Ratio</th>
          <th>Delete</th>
        </tr>
        </thead>
        <tbody>
        {images.map(image => {
          const path = `https://herbs.seremj.mn/uploads/${image.name}.${image.ext}`;
          return (
            <tr key={image.id}>
              <td>{image.ordering}</td>
              <td>
                <a href={path} target={"_blank"}>
                  <img src={path} alt={"Img"} height={70}/>
                </a>
              </td>
              <td>{image.ratio}</td>
              <td>
                <button className={'btn btn-danger'} title={"Delete"} onClick={() => del(id, image.id)}>
                  <i className={'fa fa-trash'}></i>
                </button>
              </td>
            </tr>
          )
        })}
        </tbody>
      </table>
      <hr/>
      <FileUploader url={`/herbs/${id}/images`} name={'files'} type={'image/*'} uploadComplete={() => load(id)} />
    </Modal>
  )
};

const Herbs = () => {

  const [showImagesId, setShowImagesId] = useState(null);

  const {
    items,
    setPage,
    filter,
    setFilter,
    del
  } = useListPageHelper({module: '/herbs', initialFilter: {sort: 'updated,DESC'}, initialSize: 100});

  return (    <>
      <div className="container-fluid">
        <div className="heading">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <h3>Эмийн түүхий эд</h3>
              <div>&nbsp;</div>
              <form className={'row g-3'} onSubmit={() => false}>
                <div className={'col-auto'}>
                  <input type={'text'} value={filter.name} className={"form-control form-control-sm"} name={'name'} onChange={setFilter} placeholder={'Name...'}/>
                </div>
                <div className={'col-auto'}>
                  <select name={'hasImage'} value={filter.hasImage} onChange={setFilter} className={'form-select form-select-sm'} style={{width: 100}}>
                    <option value={''}>Зураг</option>
                    <option value={'true'}>Зурагтай</option>
                    <option value={'false'}>Зураггүй</option>
                  </select>
                </div>
                <div className={'col-auto'}>
                  <select name={'hasCategory'} value={filter.hasCategory} onChange={setFilter} className={'form-select form-select-sm'} style={{width: 100}}>
                    <option value={''}>Өвчин</option>
                    <option value={'true'}>Өвчин бүргэсэн</option>
                    <option value={'false'}>Өвчин бүртгээгүй</option>
                  </select>
                </div>
                <div className={'col-auto'}>
                  <select name={'hasRecipe'} value={filter.hasRecipe} onChange={setFilter} className={'form-select form-select-sm'} style={{width: 100}}>
                    <option value={''}>Жор</option>
                    <option value={'true'}>Жор бүргэсэн</option>
                    <option value={'false'}>Жор бүртгээгүй</option>
                  </select>
                </div>
                <div className={'col-auto'}>
                  <select name={'origin'} value={filter.origin} onChange={setFilter} className={'form-select form-select-sm'} style={{width: 100}}>
                    <option value={''}>Гарал</option>
                    <option value={'ANIMAL'}>ANIMAL</option>
                    <option value={'HERB'}>HERB</option>
                    <option value={'MINERAL'}>MINERAL</option>
                  </select>
                </div>
                <div className={'col-auto'}>
                  <select name={'sort'} value={filter.sort} onChange={setFilter} className={'form-select form-select-sm'} style={{width: 200}}>
                    <option value={'updated,DESC'}>updated DESC</option>
                    <option value={'name,ASC'}>name ASC</option>
                    <option value={'nameMongolian,ASC'}>nameMongolian ASC</option>
                    <option value={'nameTibetan,ASC'}>nameTibetan ASC</option>
                    <option value={'nameTraditional,ASC'}>nameTraditional ASC</option>
                    <option value={'nameChinese,ASC'}>nameChinese ASC</option>
                    <option value={'nameInternational,ASC'}>nameInternational ASC</option>
                  </select>
                </div>
              </form>
            </div>
            <div>
              <Link to={'/herbs/register'} className="btn btn-primary btn-sm">
                <i className="fa fa-file" /> Register
              </Link>
            </div>
          </div>
        </div>
        <div className={'table-responsive'}>
          <StateChooser state={items}>
            <table className={'table table-bordered table-striped'}>
              <thead>
              <tr>
                <th width={1}>#</th>
                <th>Нэр</th>
                <th>Бусад нэрнүүд</th>
                <th>Гарал</th>
                <th>Зураг</th>
                <th>Өвчин</th>
                <th>Өвчиний төрөл</th>
                <th>Жор</th>
                <th width={1}>UpdatedAt</th>
                <th width={1}>
                  <i className={'fa fa-gears'} />
                </th>
              </tr>
              </thead>
              <tbody>
              {items && items.content.map(item => (
                <tr key={item.id + '' + item.lang}>
                  <td>
                    <Link to={`/herbs/${item.id}`} >#{item.id}</Link>
                  </td>
                  <td>{item.name}</td>
                  <td>{item.nameMongolian}, {item.nameTibetan}, {item.nameTraditional}, {item.nameChinese}, {item.nameInternational}</td>
                  <td>{item.origin}</td>
                  <td>
                    <button type={'button'} onClick={() => setShowImagesId(item.id)} className={'btn btn-light btn-sm'}>{item.images.length}</button>
                  </td>
                  <td>{item.traditionalIlls.map(item => item.nameMongolian).join(', ')}</td>
                  <td>{item.traditionalIllTypes.map(item => item.name).join(', ')}</td>
                  <td>{item.recipes.map(item => item.nameMongolian).join(', ')}</td>
                  <td style={{whiteSpace: 'nowrap'}}>
                    <RelativeTime value={item.updatedAt}/>
                  </td>
                  <td>
                    <button type={"button"} className={'btn btn-danger btn-sm'} onClick={() => del(item.id)} title={'Delete'}>
                      <i className={'fa fa-trash'} />
                    </button>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
            <Pagination page={items} onPageChange={setPage} />
          </StateChooser>
        </div>
      </div>
      {showImagesId && <ShowImages id={showImagesId} closeModal={() => setShowImagesId(null)}/>}
    </>
  )
};

export default Herbs;

import React, {useCallback} from 'react';
import {useForm} from "./Form";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const DEFAULT_CONFIG = {
  toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|', 'undo', 'redo'],
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
      { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
    ]
  }
};

const RichTextEditor = ({name, label = name, config = DEFAULT_CONFIG, required = false, errorMessage = 'Зөвшөөрөгдсөн элементүүд ашиглана уу!'}) => {

  const {formName, formState, onInputChange} = useForm();
  const value = formState[name] || '';

  const handleChange = useCallback((e, editor) => {
    onInputChange(name, editor.getData());
  }, [name, onInputChange]);

  return (
    <div className="mb-3">

      <label htmlFor={`form-${formName}-${name}`} className="form-label text-capitalize">
        {label} {required && <span className={'text-danger'}>*</span> }
      </label>

      <CKEditor
        editor={ClassicEditor}
        config={config}
        data={value}
        onChange={handleChange}
      />

      {errorMessage && <div className={`invalid-feedback`}>{errorMessage}</div>}

    </div>
  )
};

export default RichTextEditor;

/* eslint-disable jsx-a11y/anchor-is-valid */
const Pagination = ({page, onPageChange, className = ''}) => {
  const prevs = [], nexts = [];
  const count = 3;
  const start = Math.max(page.number - count, 0);
  const end = Math.min(page.number + count, page.totalPages - 1);

  for (let i=start; i<page.number; i++){
    prevs.push(
      <li key={i} className="page-item">
        <a className="page-link" href="page=i" onClick={(e) => {e.preventDefault(); onPageChange(i)}}>{i+1}</a>
      </li>
    );
  }

  for (let i=page.number+1; i<=end; i++){
    nexts.push(
      <li key={i} className="page-item">
        <a className="page-link" href="page=i" onClick={(e) => {e.preventDefault(); onPageChange(i)}}>{i+1}</a>
      </li>
    );
  }

  return (
    <div className={'row'}>
      <div className={'col'}>
        <ul className={'pagination ' + className}>
          <li className={'page-item ' + (page.first ? ' disabled' : '')}>
            <a className="page-link" href="page=prev" onClick={(e) => {e.preventDefault(); onPageChange(page.number - 1)}}>Previous</a>
          </li>
          {prevs}
          <li className="page-item active">
            <a className="page-link" >{page.number + 1}</a>
          </li>
          {nexts}
          <li className={'page-item ' + (page.last ? ' disabled' : '')}>
            <a className="page-link" href="page=next" onClick={(e) => {e.preventDefault(); onPageChange(page.number + 1)}}>Next</a>
          </li>
        </ul>
      </div>
      <div className={'col'} style={{textAlign: 'right'}}>
        <span>Нийт: {page.totalElements}</span>
      </div>
    </div>
  )
};

export default Pagination;

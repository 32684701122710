import React from 'react';
import {useForm} from "./Form";

const Radios = ({name, label = name, values, valuesId, valuesName, inline = true, required = false, errorMessage = 'Сонгоно уу!'}) => {

  const {formName, formState, onInputChange} = useForm();
  const value = formState[name];

  return (
    <div className="mb-3">
      <label className="form-label text-capitalize">
        {label} {required && <span className={'text-danger'}>*</span> }
      </label>
      <div>
        {
          values.map(v => {
            const id = valuesId ? v[valuesId] : v;
            const label = valuesName ? v[valuesName] : v;
            const checked = value === id;

            return (
              <div key={`form-${formName}-${name}-${id}`} className={'form-check' + (inline ? ' form-check-inline' : '')}>
                <input className="form-check-input" type="radio" id={`form-${formName}-${name}-${id}`}
                       name={name} value={id} checked={checked} onChange={onInputChange} required={required}/>
                <label className="form-check-label" htmlFor={`form-${formName}-${name}-${id}`}>
                  {label}
                </label>
              </div>
            )
          })
        }
      </div>

      {errorMessage && <div className={`invalid-feedback`}>{errorMessage}</div>}

    </div>
  )
};

export default Radios;

import {createPortal} from 'react-dom';

const modalRoot = document.getElementById('modal-root');

const Modal = ({title = null, children, footer = null, close = null, size = 'sm', show = true}) => {

  return createPortal((
    <>
      <div className="modal fade show" tabIndex="-1" style={{display: show ? 'block' : 'hidden'}}>
        <div className={`modal-dialog modal-${size}`}>
          <div className="modal-content">
            {title &&
              <div className="modal-header">
                <h5 className="modal-title">{title}</h5>
                {close &&
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                          onClick={close}/>
                }
              </div>
            }
            <div className="modal-body">
              {children}
            </div>
            {footer &&
              <div className="modal-footer">
                {footer}
              </div>
            }
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade-in show" id="backdrop" style={{display: show ? 'block' : 'hidden'}}/>
    </>
  ), modalRoot);
};


export default Modal;

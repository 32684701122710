import dayjs from 'dayjs'

const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime);

const FormatDateTime = ({value,pattern='YYYY-MM-DD HH:mm'}) => {
  return <>{dayjs(value).format(pattern)}</>;
};

const FormatDate = ({value,pattern='YYYY-MM-DD'}) => {
  return <>{dayjs(value).format(pattern)}</>;
};

const RelativeTime = ({value}) => {
  return <>{dayjs(value).fromNow()}</>;
}

export {
  FormatDateTime,
  FormatDate,
  RelativeTime
}

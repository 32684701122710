import React from 'react';
import {useForm} from "./Form";

const Hidden = ({name}) => {

  const {formState, onInputChange} = useForm();
  const value = formState[name] || '';

  return (
    <input type={'hidden'} name={name} value={value} onChange={onInputChange}/>
  )
};

export default Hidden;

import {useCallback, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useAxios} from "../components/AxiosProvider";
import Form from "../components/form/Form";
import Hidden from "../components/form/Hidden";
import Input from "../components/form/Input";
import {useToasts} from "../components/ToastProvider";
import MultiSelect from "../components/form/MultiSelect";
import Radios from "../components/form/Radios";
import RichTextEditor from "../components/form/RichTextEditor";
import TextArea from "../components/form/TextArea";

const HerbEdit = () => {

  const {id} = useParams();
  const {showToast} = useToasts();
  const axios = useAxios();
  const [item, setItem] = useState();
  const [loading, setLoading] = useState(true);
  const [create, setCreate] = useState();
  const [tradIlls, setTradIlls] = useState([]);
  const [tradIllTypes, setTradIllTypes] = useState([]);
  const [recipes, setRecipes] = useState([]);

  const load = useCallback((id) => {
    setLoading(true);
    console.log('load', id);
    axios.get(`/herbs/${id}`)
      .then(res => {
        console.log('load fetched data', res.data);
        setItem({
          ...res.data,
          traditionalIlls: res.data.traditionalIlls.map(item => item.id),
          traditionalIllTypes: res.data.traditionalIllTypes.map(item => item.id),
          recipes: res.data.recipes.map(item => item.id),
        });
      })
      .catch((e) => {
        showToast({title: "failed"});
        console.error('failed to fetch feedback categories', e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [axios, showToast]);

  const post = useCallback((form) => {
    axios.post('/herbs', form)
      .then(res => {
        console.log("success", res.data);
        showToast({title: 'Success'});
      })
      .catch(e => {
        console.log("failed", e);
        showToast({title: "failed"});
      })
  }, [axios, showToast]);

  const patch = useCallback((form) => {
    axios.patch(`/herbs/${form.id}`, form)
      .then(res => {
        showToast({title: 'Success'});
        console.error('success', res.data);
      })
      .catch(e => {
        showToast({title: 'failed'});
        console.error('failed', e);
      })
  }, [axios, showToast]);

  const submit = useCallback((form) => {
    console.log('submit', form);
    if (create) {
      post(form);
    } else {
      patch(form);
    }
  }, [create, patch, post]);

  const loadRefs = useCallback(() => {
    axios.get('/tradIllTypes')
      .then(res => {
        setTradIllTypes(res.data);
      });

    axios.get('/tradIlls')
      .then(res => {
        setTradIlls(res.data);
      })

    axios.get('/recipes')
      .then(res => {
        setRecipes(res.data);
      })
  }, [axios]);

  useEffect(() => {
    console.log('useEffect calls load()', id);
    loadRefs();
    if (id) {
      setCreate(false);
      load(id);
    } else {
      setCreate(true);
      setItem({});
      setLoading(false);
    }
  }, [id, load, loadRefs]);

  return (
    <>
      <div className="container">
        <div className="heading">
          <div className="d-flex justify-content-between">
            <div>
              <h3>Түүхий эд {`${id ? '#' + id + ' засах' : 'бүртгэх'}`}</h3>
            </div>
            <div>
              <Link to={'/herbs'} className="btn btn-primary btn-sm">
                <i className="fas fa-arrow-left" /> Back
              </Link>
            </div>
          </div>
        </div>
        <div className={"row"}>
          <div key={'mn'} className={'col'}>
            {!loading &&
              <Form name={'mn'} initialState={item} onSubmit={submit}>
                <Hidden name={'id'} />

                <div className={'row'}>
                  <div className={'col'}>
                    <Input name={"name"} required={true} max={255}/>
                  </div>
                  <div className={'col'}>
                    <Input name={"nameMongolian"} required={true} max={255}/>
                  </div>
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <Input name={"nameInternational"}  max={255}/>
                  </div>
                  <div className={'col'}>
                    <Input name={"nameTibetan"}  max={255}/>
                  </div>
                </div>
                <div className={'row'}>
                  <div className={'col'}>
                    <Input name={"nameTraditional"}  max={255}/>
                  </div>
                  <div className={'col'}>
                    <Input name={"nameChinese"} max={255}/>
                  </div>
                </div>

                <TextArea name={"taste"} max={1024}/>
                <RichTextEditor name={"attendance"} />
                <TextArea name={"usage"} max={4096}/>
                <MultiSelect name={'traditionalIllIds'} values={tradIlls} valuesId={'id'} valuesLabel={'nameMongolian'} required={true}/>
                <MultiSelect name={'traditionalIllTypeIds'} values={tradIllTypes} valuesId={'id'} valuesLabel={'name'} required={true}/>
                <Input name={"recipe"} label={'Recipe /Old/'} max={4096}/>
                <MultiSelect name={'recipeIds'} values={recipes} valuesId={'id'} valuesLabel={'nameMongolian'} required={true}/>
                <Radios name={'origin'} values={['HERB', 'MINERAL', 'ANIMAL']} required={true}/>
                <TextArea name={"rawProducts"} max={4096} />
                <TextArea name={"resourceAndDistribution"} max={4096} />
                <TextArea name={"containingSubstance"} max={4096} />
                <TextArea name={"recentStudies"} max={4096} />

                <div className={"text-end"}>
                  <button className={'btn btn-success'}>Submit</button>
                  <button className={'btn btn-reset'} type={'reset'}>Reset</button>
                </div>
              </Form>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default HerbEdit;

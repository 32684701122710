import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from "./Form";

const EMPTY_ARRAY = [];

const MultiSelect = ({name, label = name, values, valuesId, valuesLabel, required = false, errorMessage = 'Сонгоно уу!'}) => {

  const [shown, setShown] = useState(false);
  const {formName, formState, formSize, onInputChange} = useForm();
  const value = formState[name] || EMPTY_ARRAY;

  const toggle = useCallback((e) => {
    e.stopPropagation();
    setShown((shown) => {
      return !shown;
    })
  }, []);

  const dropdownClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const handleChange = useCallback((e) => {
    const id = +e.target.value;
    if (e.target.checked){
      onInputChange(name, [...value, id]);
    }else{
      const i = value.indexOf(id);
      if (i > -1){
        value.splice(i, 1);
        onInputChange(name, [...value]);
      }
    }
  }, [name, value, onInputChange]);

  useEffect(() => {
    const windowClickListener = function () {
      setShown(false);
    };
    window.addEventListener('click', windowClickListener);
    return () => {
      window.removeEventListener('click', windowClickListener);
    }
  }, [setShown]);

  return (
    <div className="mb-3" style={{position: 'relative'}}>

      <label htmlFor={`form-${formName}-${name}`} className="form-label text-capitalize">
        {label} {required && <span className={'text-danger'}>*</span> }
      </label>

      <div className={`form-control form-control-${formSize} ` + (errorMessage && 'is-'+errorMessage.type)}
           onClick={toggle} style={{height: 'auto', minHeight: 'calc(1.5em + 0.5rem + 2px)', cursor: 'pointer'}}>
        {values
          .filter(v => value.includes(v.id))
          .map(v => {
            const id = valuesId ? v[valuesId] : v;
            const label = valuesLabel ? v[valuesLabel] : v;
            return (
              <span key={id}>
              <span className={'badge badge-info'}>{label} </span>
              <span> </span>
            </span>
            );
          })}
      </div>

      <div className={'dropdown-menu p-2'} style={{display: (shown ? 'block': 'none'), maxHeight: 300, overflowY: 'auto'}} onClick={dropdownClick}>
        {values.map(v => {
          const id = valuesId ? v[valuesId] : v;
          const label = valuesLabel ? v[valuesLabel] : v;
          const checked = value.includes(id);
          return (
            <div key={`form-${formName}-${name}-${id}`} className={'form-check'}>
              <input className="form-check-input" type="checkbox" id={`form-${formName}-${name}-${id}`}
                     name={name} value={id} checked={checked} onChange={handleChange}/>
              <label className="form-check-label" htmlFor={`form-${formName}-${name}-${id}`}>
                {label}
              </label>
            </div>
          )
        })}
      </div>

      {errorMessage && <div className={`invalid-feedback`}>{errorMessage.message}</div>}

    </div>
  )
};

export default MultiSelect;

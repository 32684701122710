import React from 'react';
import Error from "./Error";
import Loading from "./Loading";
import Empty from "./Empty";

/**
 * @param state is undefined -> Loading
 * @param state has non null value -> children
 * @param loading
 * @param error
 * @param empty
 * @param success
 * @param state otherwise -> Error
 */
const StateChooser = ({state, loading = <Loading />, errorRetry = null, error = <Error retry={errorRetry}/>, empty = <Empty />, children}) => {

  if(state === undefined) {
    //console.debug('StateChooser: undefined');
    return loading;
  }

  if (Array.isArray(state) && state.length === 0) {
    //console.debug('StateChooser: empty array', state);
    return empty;
  }

  if (state != null && Object.keys(state).length === 0) {
    //console.debug('StateChooser: empty object', state);
    return empty;
  }

  if (state && state.content && state.content.length===0)
    return empty;

  if (typeof state === 'object' && state !== null) {
    //console.debug('StateChooser: children', state, children);
    return children;
  }

  //console.debug('StateChooser: error', state);
  return error;
};

export default StateChooser;

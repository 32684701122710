import React from 'react';

const DUMMY_FUNC = () => {};

const Error = ({children = 'Something wrong', retry = DUMMY_FUNC}) => {

  return (
    <div className={'d-flex justify-content-center align-items-center'} style={{height: '100vh'}}>
      <div role="status">
        <i className={'fa fa-info-circle'}/>
        <span> {children}</span>
      </div>
      <div>&nbsp;&nbsp;</div>
      <div role="status">
        <button type={'button'} className={'btn btn-danger btn-sm'} onClick={retry} title={'Retry'}>
          <i className={'fa fa-redo'}/>
          <span> Retry</span>
        </button>
      </div>
    </div>
  );
};

export default Error;

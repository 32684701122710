import {Link} from "react-router-dom";
import StateChooser from "../components/StateChooser";
import useListPageHelper from "../utils/UseListPageHelper";
import {RelativeTime} from "../utils/DateTimeFormats";

const TradIllTypes = () => {

  const {
    items,
    del
  } = useListPageHelper({module: '/tradIllTypes'});

  return (    <>
      <div className="container-fluid">
        <div className="heading">
          <div className="d-flex justify-content-between">
            <div>
              <h3>Өвчиний төрөл</h3>
            </div>
            <div>
              <Link to={'/tradIllTypes/register'} className="btn btn-primary btn-sm">
                <i className="fa fa-file" /> Register
              </Link>
            </div>
          </div>
        </div>
        <div className={'table-responsive'}>
          <StateChooser state={items}>
            <table className={'table table-bordered table-striped'}>
              <thead>
              <tr>
                <th width={1}>#</th>
                <th>Name</th>
                <th>Recipes</th>
                <th width={1}>UpdatedAt</th>
                <th width={1}>
                  <i className={'fa fa-gears'} />
                </th>
              </tr>
              </thead>
              <tbody>
              {items && items.map(item => (
                <tr key={item.id + '' + item.lang}>
                  <td>
                    <Link to={`/tradIllTypes/${item.id}`} >#{item.id}</Link>
                  </td>
                  <td>{item.name}</td>
                  <td>{item.recipes.map(item => item.nameMongolian).join(', ')}</td>
                  <td style={{whiteSpace: 'nowrap'}}>
                    <RelativeTime value={item.updatedAt}/>
                  </td>
                  <td>
                    <button type={"button"} className={'btn btn-danger btn-sm'} onClick={() => del(item.id)} title={'Delete'}>
                      <i className={'fa fa-trash'} />
                    </button>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </StateChooser>
        </div>
      </div>
    </>
  )
};

export default TradIllTypes;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import {NavLink, useLocation} from "react-router-dom";

const MenuItem = ({item: {icon, label, link, children}}) => {

  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  const onClickExpand = () => {
    setExpanded(!expanded);
  }

  return (
    <li>
      <div>
        <NavLink to={link} className={location.pathname === link ? 'selected' : ''}>
          <i className={icon}> </i>
          <span> {label}</span>
        </NavLink>

        {children && (
          <a onClick={onClickExpand}>
            <i className={"fa fa-angle-"+(expanded ? 'up' : 'right')}/>
          </a>
        )}
      </div>
      {children && (
        <ul style={{display: (expanded ? 'block': 'none')}}>
          {children.map((child) => <MenuItem key={child.id} item={child} />)}
        </ul>
      )}
    </li>
  );
};

export default MenuItem;

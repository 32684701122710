import React from 'react';
import {confirmable} from 'react-confirm';
import Modal from "../components/Modal";

const ConfirmDialog = ({show, proceed, confirmation,okLabel='Confirm', noLabel='Cancel'}) => (
  <Modal show={show} title={confirmation} size={'md'} close={()=>proceed(false)}>
    <div className={'text-center'}>
      <button className={'btn btn-sm btn-success'} onClick={() => proceed(true)}>{okLabel}</button>
      &nbsp;
      <button className={'btn btn-sm btn-danger'} onClick={() => proceed(false)}>{noLabel}</button>
    </div>
  </Modal>
)

export default confirmable(ConfirmDialog);


import React, {useCallback, useEffect} from 'react';

const Toast = ({toast: {key, icon = '', title, body = null, time = null, autoClose = true}, dismiss}) => {

  const handleClose = useCallback(() => {
    dismiss(key);
  }, [key, dismiss]);

  useEffect(() => {
    if (autoClose){
      const v = setTimeout(handleClose, 4_000);
      return () => {
        clearTimeout(v);
      };
    }
  }, [key, autoClose, handleClose]);

  return (
    <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true" style={{flexBasis: 'unset'}}>
      <div className="toast-header">
        <img src={icon} className="rounded mr-2" alt={icon} />
        <strong className="me-auto">{title}</strong>
        {time &&
          <small className="text-muted">{time}</small>
        }
        <button type="button" onClick={handleClose} className="btn-close" data-dismiss="toast" aria-label="Close">
        </button>
      </div>
      {body &&
        <div className="toast-body">
          {body}
        </div>
      }
    </div>
  );
};

export default Toast;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useState} from 'react';
import menu from "./assets/menu.json";
import MenuItem from "./components/MenuItem";
import {BrowserRouter as Router, Link, Route, Routes} from "react-router-dom";
import Users from "./modules/Users";
import Dashboard from "./modules/Dashboard";
import UserRegister from "./modules/UserRegister";
import {useAxios} from "./components/AxiosProvider";
import {useDropdownHandler} from "./utils/DropdownHandler";
import UserView from "./modules/UserView";
import Recipes from "./modules/Recipes";
import RecipeEdit from "./modules/RecipeEdit";
import TradIlls from "./modules/TradIlls";
import TradIllTypes from "./modules/TradIllTypes";
import Herbs from "./modules/Herbs";
import TradIllEdit from "./modules/TradIllEdit";
import TradIllTypeEdit from "./modules/TradIllTypeEdit";
import HerbEdit from "./modules/HerbEdit";

const Main = ({onSignInRequired, currentUser}) => {

  const axios = useAxios();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [userMenuOpen, userMenuRef] = useDropdownHandler();

  const openSidebarClick = useCallback(() => {
    setSidebarOpen((sidebarOpen) => {
      return !sidebarOpen;
    });
  }, []);

  const logout = useCallback(() => {
    axios.post('/logout', {})
      .then(res => {
        if(res.status === 200){
          console.log('logout success', res.data);
          onSignInRequired();
        }else{
          console.log('logout failed', res);
        }
      })
      .catch(error => {
        console.log('logout failed', error);
      });
  }, [axios, onSignInRequired]);

  return (
    <Router>
      <div className={'wrapper'}>
        <div className={'sidebar' + (sidebarOpen ? ' active' : '')} id={'sidebar'}>
          <div className="header">
            <Link to="/">Монгол Анагаах Ухаан</Link>
          </div>
          <div className={'body'}>
            <ul>
              {menu.map((item) =><MenuItem key={item.id} item={item}/>)}
            </ul>
          </div>
          <div className="footer">
            <ul>
              <li>
                <a>
                  <i className={'fa fa-user'}></i>
                </a>
              </li>
              <li>
                <div>
                  Copyright © <a target="_blank" href="https://www.otuge.tech" rel="noreferrer">Otuge Tech LLC</a> 2022
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div id={'content'}>
          <nav className="navbar sticky-top navbar-expand-sm navbar-dark bg-dark">
            <div className={'container-fluid'}>
              <a className="navbar-brand" title="open sidebar" onClick={openSidebarClick} style={{cursor: 'pointer'}}>
                <i className="fa fa-bars"/>
              </a>
              <div className="collapse navbar-collapse show">
                <ul className="navbar-nav ml-auto">
                  {/*shortcut menu*/}
                </ul>
              </div>
              <div className={'d-flex'}>
                <ul className="navbar-nav ml-auto">
                  <li className={'navbar-item dropdown' + (userMenuOpen ? ' show' : '')}>
                    <a ref={userMenuRef} className="nav-link dropdown-toggle" type="button" aria-haspopup="true" role="menuitem" aria-expanded={userMenuOpen}>
                      <i className="fa fa-user"/>
                      <span>{currentUser.loginname || currentUser.email || currentUser.phone} </span>
                      ({currentUser.role})
                    </a>
                    <div className={'dropdown-menu' + (userMenuOpen ? ' show' : '')} >
                      <Link to={`/users/${currentUser.id}`} className="dropdown-item" >
                        <i className="fa fa-user"/> Profile
                      </Link>
                      <div className="dropdown-divider"/>
                      <a className="dropdown-item" >
                        <i className="fa fa-shield-alt" /> Change password
                      </a>
                      <div className="dropdown-divider"/>
                      <a onClick={logout} className="dropdown-item" >
                        <i className="fa fa-sign-out-alt"/> Logout
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className={'container-fluid mt-2'}>
            <Routes>
              <Route path="/users/register" element={<UserRegister />}/>
              <Route path="/users/:id" element={<UserView />}/>
              <Route path="/users" element={<Users />}/>

              <Route path="/recipes/register" element={<RecipeEdit/>}/>
              <Route path="/recipes/:id" element={<RecipeEdit/>}/>
              <Route path="/recipes" element={<Recipes />}/>

              <Route path="/tradIlls/register" element={<TradIllEdit />}/>
              <Route path="/tradIlls/:id" element={<TradIllEdit />}/>
              <Route path="/tradIlls" element={<TradIlls />}/>

              <Route path="/tradIllTypes/register" element={<TradIllTypeEdit />}/>
              <Route path="/tradIllTypes/:id" element={<TradIllTypeEdit />}/>
              <Route path="/tradIllTypes" element={<TradIllTypes />}/>

              <Route path="/herbs/register" element={<HerbEdit />}/>
              <Route path="/herbs/:id" element={<HerbEdit />}/>
              <Route path="/herbs" element={<Herbs />}/>

              <Route path="/" element={<Dashboard />}/>
            </Routes>
          </div>
        </div>

      </div>
      <div className={"overlay" + (sidebarOpen ? ' active' : '')} onClick={openSidebarClick}/>
      <div className="modal fade-in" tabIndex="-1" role="dialog" style={{display:'none'}}>

      </div>
      <div className="modal-backdrop fade-in show" id="backdrop" style={{display: 'none'}}/>
    </Router>
  );
};

export default Main;

import {Link, useParams} from 'react-router-dom';
import {useAxios} from "../components/AxiosProvider";
import React, {useCallback, useEffect, useState} from "react";
import {useToasts} from "../components/ToastProvider";
import ModalForm from "../components/ModalForm";
import Input from "../components/form/Input";
import Select from "../components/form/Select";
import {FormatDateTime} from "../utils/DateTimeFormats";

const UserView = () => {

  const [currentModal, setCurrentModal] = useState();
  const {showToast} = useToasts();
  const axios = useAxios();
  const {id} = useParams();
  const [item, setItem] = useState();

  const loadItem = useCallback(() => {
    axios.get(`/users/${id}`)
      .then(res => {
        setItem(res.data);
      })
      .catch(e => {
        showToast('Failed to load item');
      });
  }, [axios, showToast, id]);

  const closeModal = useCallback(() => {
    loadItem();
    setCurrentModal(null);
  }, [loadItem]);

  const openSetPasswordModal = useCallback(() => {
    setCurrentModal('password');
  }, []);

  const openSetRoleModal = useCallback(() => {
    setCurrentModal('role');
  }, []);

  const chooseModal = useCallback((name) => {
    switch (name) {
      case 'password':
        return <SetPasswordModal id={id} closeModal={closeModal}/>;
      case 'role':
        return <SetRoleModal id={id} role={item.role} closeModal={closeModal}/>;
      default:
        return null;
    }
  }, [id, item, closeModal]);

  useEffect(() => {
    loadItem();
  }, [loadItem]);

  return (
    <>
      <div className={'container'}>
        <div className="heading">
          <div className="d-flex justify-content-between">
            <div>
              <h3>User #{item && item.id}</h3>
            </div>
            <div>
              <Link to={'/users'} className="btn btn-primary btn-sm">
                <i className="fas fa-arrow-left" /> Back
              </Link>
            </div>
          </div>
        </div>
        {
          item &&
          <table className={'table'}>
            <tbody>
              <tr><td>Id</td><td>{item.id}</td></tr>
              <tr><td>Loginname</td><td>{item.loginname}</td></tr>
              <tr><td>Email</td><td>{item.email}</td></tr>
              <tr><td>Phone</td><td>{item.phone}</td></tr>
              <tr>
                <td>Role</td>
                <td><button type={'button'} className={'btn btn-secondary btn-sm'} onClick={openSetRoleModal}>{item.role}</button> </td>
              </tr>
              <tr>
                <td>Password</td>
                <td><button type={'button'} className={'btn btn-secondary btn-sm'} onClick={openSetPasswordModal}>Set Password</button></td>
              </tr>
              <tr><td>RegisteredAt</td><td><FormatDateTime pattern={'YYYY-MM-DD HH:mm'} value={item.createdAt}/></td></tr>
            </tbody>
          </table>
        }
      </div>
      {chooseModal(currentModal)}
    </>
  )
};

export default UserView;

const SetPasswordModal = ({id, closeModal}) => {

  const axios = useAxios();
  const {showToast} = useToasts();

  const submit = useCallback((form) => {
    console.log('setting password for user: ', id);
    axios.patch(`/users/${id}/password`, form)
      .then(res => {
        showToast({title: 'Password changed successfully'});
        closeModal();
      })
  }, [axios, closeModal, id, showToast]);

  return (
    <ModalForm title={'Set Password For User #' + id} show={true} close={closeModal} submit={submit} initialState={{}}>
      <Input name={'newPassword'} type={'password'} required={true}/>
    </ModalForm>
  )
};

const SetRoleModal = ({id, role, closeModal}) => {

  const axios = useAxios();
  const {showToast} = useToasts();

  const submit = useCallback((form) => {
    console.log('setting password for user: ', id);
    axios.patch(`/users/${id}/role`, form)
      .then(res => {
        showToast({title: 'Role changed successfully'});
        closeModal();
      })
  }, [axios, closeModal, id, showToast]);

  return (
    <ModalForm title={'Set Role' + id} show={true} close={closeModal} submit={submit} initialState={{role: role}}>
      <Select name={'role'} required={true} values={['ROLE_ADMIN', 'ROLE_DEV', 'ROLE_MANAGER']}/>
    </ModalForm>
  )
};

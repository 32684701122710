import React, {useCallback, useEffect} from 'react';
import Main from "./Main";
import Login from "./Login";

import ToastProvider from "./components/ToastProvider";
import AxiosProvider, {useAxios} from "./components/AxiosProvider";
import StateChooser from "./components/StateChooser";
import {useCurrentUser} from "./components/CurrentUserProvider";

function App() {
  console.log('App');
  const [currentUser, setCurrentUser] = useCurrentUser();
  const axios = useAxios();

  const onSignInRequired = useCallback(() => {
    setCurrentUser(null);
  }, [setCurrentUser]);

  const fetchUserInfo = useCallback(() => {
    console.log('fetching user profile');
    axios.get('/users/me')
      .then(res => res.data)
      .then(data => {
        console.log('fetchUserInfo success', data);
        setCurrentUser(data);
      })
      .catch(error => {
        console.log('fetchUserInfo failed', error);
        setCurrentUser(null);
      })
      .finally(() => {
        //setInitialized(true);
      });
  }, [axios, setCurrentUser]);

  const onSignInSuccess = useCallback((user) => {
    if (!user)
      fetchUserInfo();
    else{
      setCurrentUser(user);
    }
  }, [fetchUserInfo, setCurrentUser]);

  useEffect(() => {
    console.log('App useEffect called');
    fetchUserInfo();
  }, [fetchUserInfo]);

  return (
    <div className="App">
      <ToastProvider>
        <AxiosProvider>
          <StateChooser state={currentUser} error={<Login onSignInSuccess={onSignInSuccess}/>}>
            <Main onSignInRequired={onSignInRequired} currentUser={currentUser}/>
          </StateChooser>
        </AxiosProvider>
      </ToastProvider>
    </div>
  );
}

export default App;

import {useToasts} from "./ToastProvider";
import {useAxios} from "./AxiosProvider";
import {useCallback, useState} from "react";
import ReadableLength from "./ReadableLength";

const MAX_FILE_SIZE = 1024 * 1024 * 64;
const MAX_REQ_SIZE = 1024 * 1024 * 512;

const FileUploader = ({url, name, uploadComplete, type='*/*'}) => {

  const {showToast} = useToasts();
  const axios = useAxios();
  const [uploadProgress, setUploadProgress] = useState();

  const add = useCallback((e) => {
    console.log(e);

    const config = {
      onUploadProgress: (progressEvent) => {
        console.log(progressEvent);

        if (progressEvent.loaded === progressEvent.total) {
          setUploadProgress(undefined);
        } else {
          setUploadProgress(Math.round(progressEvent.loaded * 100 / progressEvent.total));
        }
      }
    }

    const reqBody = new FormData();
    //reqBody.append('lang', lang);

    let isOk = true;
    let totalSize = 0;
    for (const file of e.target.files) {
      if (file.size > MAX_FILE_SIZE) {
        showToast({title: <div>Max file <ReadableLength length={MAX_FILE_SIZE}/> size exceeded</div>});
        isOk = false;
        break;
      }
      totalSize += file.size;
      reqBody.append(name, file);
    }

    if (totalSize > MAX_REQ_SIZE) {
      showToast({title: <div>Max upload <ReadableLength length={MAX_REQ_SIZE}/> size exceeded</div>});
      isOk = false;
    }

    if (isOk) {
      axios.post(url, reqBody, config)
        .then(res => res.data)
        .then(data => {
          showToast({title: 'Upload Success'});
        })
        .catch(e => {
          showToast({title: 'Upload Failed', body: e.message});
        })
        .finally(() => {
          e.target.value = [];
          uploadComplete();
        });
    }

  }, [axios, showToast, uploadComplete, url]);

  return (
    <form style={{width: 97}}>
      <div className={'progress'} style={{height: 31, display: (uploadProgress !== undefined ? 'block' : 'none')}}>
        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
             style={{height: 31, width: `${uploadProgress}%`}}
             aria-valuenow={uploadProgress} aria-valuemin="0" aria-valuemax="100">{uploadProgress}%
        </div>
      </div>
      <input type={'file'} multiple={true} name={name} className={'form-control form-control-sm'}
             accept={type}
             style={{display: (uploadProgress === undefined ? 'block' : 'none')}}
             onChange={add}/>
    </form>
  )
}

export default FileUploader;

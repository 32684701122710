import React, {createContext, useContext} from "react";
import axios from 'axios';

//ref: https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token

console.log('axios initializing');

axios.defaults.baseURL ='/api/v1/main';

const csrf = {
  token: null,
  parameterName: "_csrf",
  headerName: "X-CSRF-TOKEN"
};

const initCSRF = async () => {
  return axios.get('/csrf')
    .then(res => res.data)
    .then(data => {
      console.log('new csrf fetched', data);
      csrf.token = data.token;
    })
    .catch(error => {
      console.log('new csrf fetch failed', error);
      csrf.token = null;
    });
}

const axiosAPI = axios.create();

axiosAPI.interceptors.request.use(
  async config => {
    config['Accept'] = 'application/json';
    if (config.method === 'get'){
      return config;
    }
    config['Content-Type'] = 'application/json';
    if (!csrf.token){
      await initCSRF();
    }
    if (!csrf.token){
      throw new axios.Cancel('CSRF fetch failed. Terminating request');
    }
    config.headers[csrf.headerName] = csrf.token;
    return config;
  },
  error => Promise.reject(error)
);

axiosAPI.interceptors.response.use(
  res => {
    if (res.data === ''){
      res.data = null;
    }
    return res;
  },
  async error => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && error.config.url !== '/users/me') {
      console.warn('Session expired. Reloading window', error);
      window.location.reload();
    }
    if (error.response && error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      await initCSRF();
      originalRequest.headers[csrf.headerName] = csrf.token;
      return axiosAPI(originalRequest);
    }
    return Promise.reject(error);
  }
);

const AxiosContext = createContext(axiosAPI);

const AxiosProvider = ({children}) => {
  return (
    <AxiosContext.Provider value={axiosAPI}>
      {children}
    </AxiosContext.Provider>
  )
};

export default AxiosProvider;

export const useAxios = () => {
  const ctx = useContext(AxiosContext);
  if (!ctx){throw Error('The useAxios hook must be called from a descendent of the AxiosProvider.');}
  return ctx;
}

import React from 'react';
import {createPortal} from 'react-dom';
import Form from "./form/Form";

const modalRoot = document.getElementById('modal-root');

const ModalForm = ({title, close, children, submit, initialState}) => {
  return createPortal(
    <>
      <Form className={"modal fade show"} style={{display: 'block'}}
            name={title}
            initialState={initialState}
            onSubmit={submit}
            id="normalModal" tabIndex="-1"
            data-bs-backdrop="static" data-bs-keyboard="false"
            aria-labelledby="normalModalLabel" aria-hidden={false} aria-modal={true} role={'dialog'}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="normalModalLabel">{title}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={close}/>
            </div>
            <div className="modal-body">
              {children}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={close}>Close</button>
              <button type="submit" className="btn btn-primary">Submit</button>
            </div>
          </div>
        </div>
      </Form>
      <div className="modal-backdrop fade-in show" id="backdrop" style={{display: 'block'}}/>
    </>,
    modalRoot
  );
};

export default ModalForm;

import React, {createContext, useCallback, useContext, useState} from 'react';

const FormContext = createContext();

const Form = ({name = 'default', initialState, onSubmit, children, className = null, size = 'sm', style}) => {

  const [state, setState] = useState(initialState);
  const [files, setFiles] = useState({});
  const [errors, setErrors] = useState({}); // TODO fill error data from server validation result.

  const submit = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(`form ${name} submitted`, e.target, state);

    if (!e.target.checkValidity()) {
      console.log('form is invalid', e.target);
      e.target.classList.add('was-validated');
      e.target.querySelector(':invalid').focus();
      return;
    }

    onSubmit(state, files);
  }, [name, state, files, onSubmit]);

  const reset = useCallback(() => {
    setState(initialState);
  }, [initialState, setState]);

  const onInputChange = useCallback((name, value) => {
    if (typeof name !== 'string' && value === undefined){
      //console.log('onInputChange. event received', name);
      const e = name;
      name = e.target.name;
      value = e.target.value;
    }else{
      //console.log('onInputChange. name value received', name, value);
    }
    setState((state) => {
      return {...state, [name]: value}
    });
  }, [setState]);

  const onFileChange = useCallback((name, value) => {
    if (typeof name !== 'string' && value === undefined){
      const e = name;
      name = e.target.name;
      value = e.target.files;
      //console.log('onFileChange. event received', name, value);
    }else{
      //console.log('onFileChange. name, value received', name, value);
    }
    setFiles({...files, [name]: value});
  }, [files]);

  return (
    <FormContext.Provider value={{name, state, errors, onInputChange, files, onFileChange, size}}>
      <form onSubmit={submit} onReset={reset} className={className} style={style} noValidate={true}>
        {children}
      </form>
    </FormContext.Provider>
  )
}

export default Form;
export const useForm = () => {
  const ctx = useContext(FormContext);
  if (!ctx){throw Error('The useForm hook must be called from a descendent of the Form.');}
  return {
    formName: ctx.name,
    formState: ctx.state,
    formErrors: ctx.errors,
    formSize: ctx.size,
    onInputChange: ctx.onInputChange,
    formFiles: ctx.files,
    onFileChange: ctx.onFileChange
  };
}

export const serverErrorToText = (serverError) => {
  if(serverError.errors) {
    return serverError.errors.map(e => e.field + ': ' + e.defaultMessage).join('<br />');
  } else {
    return serverError.message;
  }
}

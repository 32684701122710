import React, {useCallback} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Form, {serverErrorToText} from "../components/form/Form";
import Input from "../components/form/Input";
import {useAxios} from "../components/AxiosProvider";
import {useToasts} from "../components/ToastProvider";

const UserRegister = () => {

  const navigate = useNavigate();
  const {showToast} = useToasts();
  const axios = useAxios();

  const submit = useCallback((form) => {
    console.log('user register form submitted', form);

    axios.post('/users', form)
      .then(res => res.data)
      .then(data => {
        console.log(data);
        showToast({title: 'user form submit success'});
        navigate('/users');
      })
      .catch(error => {
        if (error.response?.status === 400) {
          showToast({title: 'Server Validation Failed', body: serverErrorToText(error.response.data)});
        } else {
          showToast({title: 'Form submit failed'});
        }
      });

  }, [axios, navigate, showToast]);

  /*useEffect(() => {
    load();
  }, [load]);*/

  return (
    <div className={'container'}>
      <div className="heading">
        <div className="d-flex justify-content-between">
          <div>
            <h3>Register user</h3>
          </div>
          <div>
            <Link to={'/users'} className="btn btn-primary btn-sm">
              <i className="fas fa-arrow-left" /> Back
            </Link>
          </div>
        </div>
      </div>
      <Form name={'userRegisterForm'} initialState={{}} onSubmit={submit}>
        <Input name={'loginname'} required={true}/>
        <Input name={'email'} required={true}/>
        <Input name={'phone'} required={true}/>
        <Input name={'password'} type={'password'} required={true}/>
        {/*<Input name={'passwordConfirm'} type={'password'} required={true}/>*/}

        <button className={'btn btn-success'}>Submit</button>
        <button className={'btn btn-reset'} type={'reset'}>Reset</button>
      </Form>
    </div>
  );
};

export default UserRegister;

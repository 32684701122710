import React, {useCallback} from 'react';
import {useForm} from "./Form";

const Checks = ({name, label = name, values, valuesId, valuesName, inline = true, required = false, errorMessage = null}) => {

  const {formName, formState, onInputChange} = useForm();
  const value = formState[name] || [];

  const handleChange = useCallback((e) => {
    if (e.target.checked){
      onInputChange(name, [...value, e.target.value]);
    }else{
      const i = value.indexOf(e.target.value);
      if (i > -1){
        value.splice(i, 1);
        onInputChange(name, [...value]);
      }else{
        console.log('checkboxes changed. but no item to delete');
      }
    }

  }, [onInputChange, name, value]);

  return (
    <div className="mb-3">
      <label className="form-label text-capitalize">
        {label} {required && <span className={'text-danger'}>*</span> }
      </label>
      <div>
        {
          values.map(v => {
            const id = valuesId ? v[valuesId] : v;
            const label = valuesName ? v[valuesName] : v;
            const checked = value.includes(id);

            return (
              <div key={`form-${formName}-${name}-${id}`} className={'form-check' + (inline ? ' form-check-inline' : '')}>
                <input className="form-check-input" type="checkbox" id={`form-${formName}-${name}-${id}`}
                       name={name} value={id} checked={checked} onChange={handleChange}/>
                <label className="form-check-label" htmlFor={`form-${formName}-${name}-${id}`}>
                  {label}
                </label>
              </div>
            )
          })
        }
      </div>

      {errorMessage && <div className={`invalid-feedback`}>{errorMessage}</div>}

    </div>
  )
};

export default Checks;


const KB = 1024;
const MB = KB * 1024;
const GB = MB * 1024;

const ReadableLength = ({length, fraction = 1}) => {

  if (length > GB) {
    return Number(length / GB).toFixed(fraction) + 'GB';
  }

  if (length > MB) {
    return Number(length / MB).toFixed(fraction) + 'MB';
  }

  if (length > KB) {
    return Number(length / KB).toFixed(fraction) + 'KB';
  }

  return length + 'B';
}

export default ReadableLength;

import {useCallback, useEffect, useState} from "react";
import {useAxios} from "../components/AxiosProvider";
import {useToasts} from "../components/ToastProvider";
import {confirm} from '../components/Confirm'

const useListPageHelper = ({module, loadItemsAuto = false, initialFilter = {}, initialSize = 20}) => {

  const {showToast} = useToasts();
  const axios = useAxios();
  const [items, setItems] = useState(undefined);
  const [filter, setFilter] = useState(initialFilter);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(initialSize);

  const loadItems = useCallback(() => {
    const config = {
      params: {
        ...filter,
        page,
        size
      }
    };

    axios.get(module, config)
      .then(res => res.data)
      .then(data => {
        setItems(data);
      })
      .catch(error => {
        console.log('ListHelper error ', error);
        setItems(null);
      })
    ;
  }, [module, axios, setItems, filter, page, size]);

  const updateFilter = useCallback((e) => {
    setFilter((filter) => {
      return {...filter, [e.target.name]: e.target.value};
    });
    setPage(0);
    if (loadItemsAuto) {
      loadItems();
    }
  }, [loadItemsAuto, setFilter, setPage, loadItems]);

  const updatePage = useCallback((page) => {
    console.log('updatePage', page);
    setPage(page);
    loadItems();
  }, [setPage, loadItems]);

  const updateSize = useCallback((size) => {
    console.log('updateSize', size);
    setSize(size);
    loadItems();
  }, [setSize, loadItems]);

  const del = useCallback((id) => {
    confirmable(
      'Are you sure about delete this?',
      () => {
        axios.delete(module + '/' + id)
          .then(res => res.data)
          .then(data => {
            console.info(`ListHelper ${module} del`, data);
            //showToast({title: data.text, type: data.type});
            showToast({title: `Deleted: ${id}`});
          })
          .catch(error => {
            showToast({title: `Failed to delete ${id}`});
            console.error(`ListHelper ${module} del`, error);
          })
          .finally(() => {
            loadItems();
          })
        ;
      });
  }, [axios, loadItems, module, showToast]);

  const confirmable = async (title, callback) => {
    if (await confirm({confirmation: title})) {
      console.log(`${title} 👉 accepted`);
      callback();
    } else {
      console.log(`${title} 👉 declined`);
    }
  }

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  return {
    items, loadItems,
    filter, setFilter: updateFilter,
    page, setPage: updatePage,
    size, setSize: updateSize,
    del
  }
}

export default useListPageHelper;

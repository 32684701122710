import React from 'react';

const Empty = ({children = 'Empty'}) => {

  return (
    <div className={'d-flex justify-content-center align-items-center'} style={{height: '100vh'}}>
      <div role="status">
        <i className={'fa fa-info-circle'}/>
        <span> {children}</span>
      </div>
    </div>
  );
};

export default Empty;
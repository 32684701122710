import React from 'react';
import {useForm} from "./Form";

const TextArea = ({name, label = name, required = false, minLength, maxLength, errorMessage = 'Зөвшөөрөгдсөн тэмдэгтүүд ашиглан бичинэ үү!'}) => {

  const {formName, formState, formSize, onInputChange} = useForm();
  const value = formState[name] || '';

  return (
    <div className="mb-3">

      <label htmlFor={`form-${formName}-${name}`} className="form-label text-capitalize">
        {label} {required && <span className={'text-danger'}>*</span> }
      </label>

      <textarea id={`form-${formName}-${name}`}
             name={name} value={value}
             className={`form-control form-control-${formSize} `}
             required={required} onChange={onInputChange} minLength={minLength} maxLength={maxLength}/>

      {errorMessage && <div className={`invalid-feedback`}>{errorMessage}</div>}

    </div>
  )
};

export default TextArea;

import {Link} from "react-router-dom";
import StateChooser from "../components/StateChooser";
import useListPageHelper from "../utils/UseListPageHelper";
import Pagination from "../components/Pagination";

const Users = () => {

  const {
    items,
    setPage,
    setFilter,
    del
  } = useListPageHelper({module: '/users'});

  return (
    <>
      <div className="container-fluid">
        <div className="heading">
          <div className={'d-flex justify-content-between'}>
            <div className="d-flex">
              <h3>Хэрэглэгчид</h3>
              <div>&nbsp;</div>
              <form className={'row g-3'}>
                <div className={'col-auto'}>
                  <select name={'admin'} onChange={setFilter} className={'form-select form-select-sm'} style={{width: 100}}>
                    <option value={'true'}>Admin</option>
                    <option value={'false'}>Normal User</option>
                  </select>
                </div>
                <div className={'col-auto'}>
                  <input type={'text'} className={"form-control form-control-sm"} name={'loginname'} onChange={setFilter} placeholder={'LoginName...'}/>
                </div>
                <div className={'col-auto'}>
                  <input type={'text'} className={"form-control form-control-sm"} name={'email'} onChange={setFilter} placeholder={'Email...'}/>
                </div>
                <div className={'col-auto'}>
                  <input type={'text'} className={"form-control form-control-sm"} name={'phone'} onChange={setFilter} placeholder={'Phone...'}/>
                </div>
              </form>
            </div>
            <div>
              <Link to={'/users/register'} className="btn btn-primary btn-sm">
                <i className="fa fa-file" /> Register
              </Link>
            </div>
          </div>
        </div>
        <div className={'table-responsive'}>
          <StateChooser state={items && items.content}>
            <table className={'table table-bordered table-striped'}>
              <thead>
              <tr>
                <th width={1}>#</th>
                <th>LoginName</th>
                <th width={1}>Role</th>
                <th width={1}>Email</th>
                <th width={1}>Phone</th>
                <th width={1}>
                  <i className={'fa fa-gears'} />
                </th>
              </tr>
              </thead>
              <tbody>
              {items && items.content && items.content.map(item => (
                <tr key={item.id}>
                  <td>
                    <Link to={`/users/${item.id}`} >#{item.id}</Link>
                  </td>
                  <td>{item.loginname}</td>
                  <td>{item.role}</td>
                  <td>{item.email}</td>
                  <td>{item.phone}</td>
                  <td>
                    <button type={"button"} className={'btn btn-danger btn-sm'} onClick={() => del(item.id)} title={'Delete'}>
                      <i className={'fa fa-trash'} />
                    </button>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
            <Pagination page={items} className={'justify-content-center'} onPageChange={setPage}/>
          </StateChooser>
        </div>
      </div>
    </>
  )
};

export default Users;

import {useCallback, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useAxios} from "../components/AxiosProvider";
import Form from "../components/form/Form";
import Hidden from "../components/form/Hidden";
import Input from "../components/form/Input";
import {useToasts} from "../components/ToastProvider";
import Checks from "../components/form/Checks";
import MultiSelect from "../components/form/MultiSelect";

const TradIllTypeEdit = () => {

  const {id} = useParams();
  const {showToast} = useToasts();
  const axios = useAxios();
  const [item, setItem] = useState();
  const [loading, setLoading] = useState(true);
  const [create, setCreate] = useState();
  const [recipes, setRecipes] = useState([]);

  const load = useCallback((id) => {
    setLoading(true);
    console.log('load', id);
    axios.get(`/tradIllTypes/${id}`)
      .then(res => {
        console.log('load fetched data', res.data);
        if (res.data) {
          setCreate(false);
          setItem(res.data);
          setItem({
            id: res.data.id,
            name: res.data.name,
            recipes: res.data.recipes?.map(item => item.id)
          });
        } else {
          setCreate(true);
          setItem({id: id});
        }
      })
      .catch((e) => {
        showToast({title: "failed"});
        console.error('failed to fetch feedback categories', e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [axios, showToast]);

  const post = useCallback((form) => {
    axios.post('/tradIllTypes', form)
      .then(res => {
        console.log("success", res.data);
        showToast({title: 'Success'});
      })
      .catch(e => {
        console.log("failed", e);
        showToast({title: "failed"});
      })
  }, [axios, showToast]);

  const patch = useCallback((form) => {
    axios.patch(`/tradIllTypes/${form.id}`, form)
      .then(res => {
        showToast({title: 'Success'});
        console.error('success', res.data);
      })
      .catch(e => {
        showToast({title: 'failed'});
        console.error('failed', e);
      })
  }, [axios, showToast]);

  const submit = useCallback((form) => {
    console.log('submit', form);
    if (create) {
      post(form);
    } else {
      patch(form);
    }
  }, [create, patch, post]);

  const loadRecepes = useCallback(() => {
    axios.get('/recipes')
      .then(res => {
        setRecipes(res.data);
      })
  }, [axios]);

  useEffect(() => {
    console.log('useEffect calls load()', id);
    loadRecepes();
    if (id) {
      load(id);
    } else {
      setCreate(true);
      setItem({});
      setLoading(false);
    }
  }, [id, load, loadRecepes]);

  return (
    <>
      <div className="container">
        <div className="heading">
          <div className="d-flex justify-content-between">
            <div>
              <h3>Өвчиний ангилал {`${id ? '#' + id + ' засах' : 'бүртгэх'}`}</h3>
            </div>
            <div>
              <Link to={'/tradIllTypes'} className="btn btn-primary btn-sm">
                <i className="fas fa-arrow-left" /> Back
              </Link>
            </div>
          </div>
        </div>
        <div className={"row"}>
          <div key={'mn'} className={'col'}>
            {!loading &&
              <Form name={'mn'} initialState={item} onSubmit={submit}>
                <Hidden name={'id'} />
                <Input name={"name"} />
                <Checks name={'recipes'} values={recipes} valuesId={'id'} valuesName={'nameMongolian'}/>

                <div className={"text-end"}>
                  <button className={'btn btn-success'}>Submit</button>
                  <button className={'btn btn-reset'} type={'reset'}>Reset</button>
                </div>
              </Form>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default TradIllTypeEdit;

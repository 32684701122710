import React, {useCallback, useState} from 'react';
import {useForm} from "./Form";

const Select = ({name, label = name, values, valuesId, valuesLabel, required = false, errorMessage = 'Сонгоно уу!', onChange}) => {

  const {formName, formState, formSize, onInputChange} = useForm();
  const value = formState[name];
  const [multi] = useState(() => Array.isArray(value));

  const handleChange = useCallback((e) => {
    if (multi){
      let val = Array.from(e.target.selectedOptions, option => option.value);
      onInputChange(name, val);
    }else{
      onInputChange(e);
    }
    if (onChange) {
      onChange(e);
    }
  }, [multi, name, onChange, onInputChange]);

  return (
    <div className="mb-3">

      <label htmlFor={`form-${formName}-${name}`} className="form-label text-capitalize">
        {label} {required && <span className={'text-danger'}>*</span> }
      </label>

      <select id={`form-${formName}-${name}`}
              name={name} value={value || ''}
              className={`form-select form-select-${formSize} ` + (errorMessage && 'is-'+errorMessage.type)}
              multiple={multi}
              required={required} onChange={handleChange} >
        {!required && !value && (<option>---</option>)}
        {values.map(v => {
          const id = valuesId ? v[valuesId] : v;
          const label = valuesLabel ? v[valuesLabel] : v;
          return (<option key={id} value={id}>{label}</option>);
        })}
      </select>

      {errorMessage && <div className={`invalid-feedback`}>{errorMessage}</div>}

    </div>
  )
};

export default Select;

import {useCallback, useEffect, useRef, useState} from 'react';

export const useDropdownHandler = ({defaultOpen = false} = {}) => {

  const [open, setOpen] = useState(defaultOpen);
  const handlerRef = useRef();

  const toggle = useCallback((e) => {
    e.stopPropagation();
    setOpen((open) => {
      return !open;
    })
    return false;
  }, [setOpen]);

  useEffect(() => {
    const windowEventListener = () => {
      setOpen(false);
    };
    window.addEventListener('click', windowEventListener);
    return () => {
      window.removeEventListener('click', windowEventListener);
    }
  }, [setOpen]);

  useEffect(() => {
    const handler = handlerRef.current;
    handler.addEventListener('click', toggle);
    return () => {
      handler.removeEventListener('click', toggle);
    };
  }, [toggle, handlerRef]);

  return [open, handlerRef];
};

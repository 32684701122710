import React, {createContext, useCallback, useContext, useState} from 'react';
import Toast from "./Toast";

const ToastContext = createContext();

const ToastProvider = ({children}) => {

  const [toasts, setToasts] = useState([]);

  const generateKey = useCallback(() => {
    return Math.random().toString(36).substr(2, 10);
  }, []);

  const dismiss = useCallback((key) => {
    setToasts((items) => {
      const i = items.findIndex(t => t.key === key);
      if (i === -1){
        console.warn('can\'t find index', items, ' ? ', key);
        return items;
      }
      items.splice(i, 1);
      return [...items];
    });
  }, []);

  const show = useCallback(({title, body = null, autoClose = true}) => {
    setToasts((items) => {
      const key = generateKey();
      const newToast = {key, title, body, autoClose};
      return [...items, newToast];
    });
  }, [generateKey]);

  return (
    <ToastContext.Provider value={{show, dismiss}}>
      {children}
      <div className={'position-fixed bottom-0 end-0 p-3'} style={{zIndex: 1056}}>
        {toasts.map(t => <Toast key={t.key} toast={t} dismiss={dismiss} />)}
      </div>
    </ToastContext.Provider>
  )
};
export default ToastProvider;
export const useToasts = () => {
  const ctx = useContext(ToastContext);
  if (!ctx){throw Error('The useToasts hook must be called from a descendent of the ToastProvider.');}
  return {
    showToast: ctx.show,
    dismissToast: ctx.dismiss
  };
}

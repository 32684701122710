import React, {useCallback} from 'react';
import {useForm} from "./Form";

const Check = ({name, label = name, required = false, valueLabel = 'On', errorMessage = 'Сонгоно уу!'}) => {

  const {formName, formState, onInputChange} = useForm();
  const value = formState[name];

  const handleChange = useCallback((e) => {
    onInputChange(name, e.target.checked);
  }, [onInputChange, name]);

  return (
    <div className="mb-3">
      {label &&
        <label className="form-label text-capitalize">
          {label} {required && <span className={'text-danger'}>*</span> }
        </label>
      }
      <div>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" id={`form-${formName}-${name}`}
                 name={name} checked={value} onChange={handleChange}/>
          <label className="form-check-label" htmlFor={`form-${formName}-${name}`}>
            {valueLabel}
          </label>
        </div>
      </div>

      {errorMessage && <div className={`invalid-feedback`}>{errorMessage}</div>}

    </div>
  )
};

export default Check;

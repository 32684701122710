import React from 'react';

const Dashboard = () => {

  return (
    <>
      <div className={'container'}>
        <div className="heading">
          <div className="d-flex justify-content-between">
            <div>
              <h3>Dashboard</h3>
            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
    </>
  )
};

export default Dashboard;

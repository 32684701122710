import {createContext, useContext, useState} from "react";

const CurrentUserContext = createContext(undefined);

const CurrentUserProvider = ({children}) => {

  const state = useState(undefined);

  return (
    <CurrentUserContext.Provider value={state}>
      {children}
    </CurrentUserContext.Provider>
  )
};

export default CurrentUserProvider;

export const useCurrentUser = () => {
  const ctx = useContext(CurrentUserContext);
  if (!ctx) throw new Error("CurrentUserProvider missing");
  return ctx;
}

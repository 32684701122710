import React from 'react';
import {useForm} from "./Form";

const Input = ({name, label = name, type = 'text',
                 required = false, minLength, maxLength, pattern, min, max, step,
                 errorMessage = 'Зөв бөглөнө үү!',
                 autoFocus = false, autoComplete = 'on'}) => {

  const {formName, formState, formErrors, formSize, onInputChange} = useForm();
  const value = formState[name] || '';
  const error = formErrors[name];

  return (
    <div className="mb-3">

      <label htmlFor={`form-${formName}-${name}`} className="form-label text-capitalize">
        {label} {required && <span className={'text-danger'}>*</span> }
      </label>

      <input id={`form-${formName}-${name}`}
             name={name} value={value} type={type}
             className={`form-control form-control-${formSize} ` + (error && 'is-'+error.type)}
             required={required} minLength={minLength} maxLength={maxLength} pattern={pattern} min={min} max={max} step={step}
             onChange={onInputChange} autoFocus={autoFocus} autoComplete={autoComplete}/>

      <div className={'invalid-feedback'}>{errorMessage}</div>

    </div>
  )
};

export default Input;
